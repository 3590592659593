let prefix = '/api/sap/business-partners';
function getPartners(type) {
    return window.axios.get(prefix+'/index/'+type)
}
function getAddresses(code) {
    return window.axios.get(prefix+'/addresses/'+code)
}
export default {
    getPartners,
    getAddresses,
}