let prefix = '/api/sap/customer-invoices-report';

function getInvoicesCustomer(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function getInvoicesServices(date1, date2){
    return window.axios.get(prefix+'/general?date1='+date1+'&date2='+date2);
}
function getInvoice(docNum){
    return window.axios.get(prefix+'/getInvoice/'+docNum);
}
function getSales(date1,date2){
    return window.axios.get(prefix+'/sales/'+date1+'/'+date2);
}
function getSalesExport(data){
    return window.axios.post(prefix+'/sales/export',data,{ responseType: 'blob' });
}
export default {
    getInvoicesCustomer, getInvoice, getInvoicesServices, getSales, getSalesExport
}