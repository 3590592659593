let prefix = '/api/sap/items-warehouses';
function getItemsWarehouse(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function stock(data){
    return window.axios.get(prefix+'/StockWhs',{params: data});
}
export default {
    getItemsWarehouse,
    stock
}