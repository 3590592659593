let namespaced = true
import apiSubItems from '../../../api/sap/sub_items_sap.js';
import apiProduction from '../../../api/sap/productions_sap.js';
import apiInventories from '../../../api/sap/inventories_sap.js';
import apiEntries from '../../../api/sap/entries_sap.js';
import apiDeliveries from '../../../api/sap/deliveries_sap.js';
import _ from 'lodash';

let state = {
    
    flagData : false,
    superItem: null,
    superItemComplete: ['PCFO0001'],
    extraItemComplete: ['PCFO0002'],
    rementeItemComplete: ['PCFO0003'],
    extraItem: null,
    remanenteItem: null,
    cAlternoItem: ['PCFO0005'],
    cAlternoMpItem: ['MPCO0001'],
    production_sap: null,
    production_sap_original: null,
    inventories_sap: null,
    inventories_sap_original: null,
    entries_sap: null,
    entries_sap_original: null,
    deliveries_sap: null,
    deliveries_sap_original: null,
    selectWhs: { WhsCode: '*', WhsName: 'Todos' },
    WhsArray: [{ WhsCode: 'PT', WhsName: 'Proeco Tizayuca' },{ WhsCode: 'PC', WhsName: 'Proeco Celaya' }, { WhsCode: '*', WhsName: 'Todos' }]
}
let mutations = {
    MUTATION_SET_PRODUCTION_SAP(state, data) {
        state.production_sap = data;
        state.production_sap_original = data;
    },
    MUTATION_SET_INVENTORIES_SAP(state, data) {
        state.inventories_sap = data;
        state.inventories_sap_original = data;
    },
    MUTATION_SET_ENTRIES_SAP(state, data) {
        state.entries_sap = data;
        state.entries_sap_original = data;
    },
    MUTATION_SET_DELIVERIES_SAP(state, data) {
        state.deliveries_sap = data;
        state.deliveries_sap_original = data;
    },
    MUTATION_SET_SUPER_ITEM(state, data) {
  
        let tempo = _.without(data, "PCFO0005");
        tempo.push('PCFO0001')
        state.superItem = tempo;
    },
    MUTATION_SET_EXTRA_ITEM(state, data) {
         let tempo = _.without(data, "PCFO0005");
        tempo.push('PCFO0002')
        state.extraItem = tempo;

    },
    MUTATION_SET_REMANENTE_ITEM(state, data) {
         let tempo = _.without(data, "PCFO0005");
        tempo.push('PCFO0003')
        state.remanenteItem = tempo;
    },
    MUTATION_SET_WAREHOUSE_SELECTED(state, data) {
        state.selectWhs = data;
    },
    MUTATION_SET_FLAG_DATA_SAP(state, value) {
        state.flagData = value;
    },
  
    MUTATION_SET_FILTERS(state) {
        state.production_sap = state.production_sap_original;
        state.inventories_sap = state.inventories_sap_original;
        state.entries_sap = state.entries_sap_original;
        state.deliveries_sap = state.deliveries_sap_original;

        state.production_sap = (state.selectWhs.WhsCode == '*') ? state.production_sap : _.filter(state.production_sap,  (item) => _.startsWith(item.Warehouse, state.selectWhs.WhsCode));
        state.inventories_sap= (state.selectWhs.WhsCode == '*') ? state.inventories_sap: _.filter(state.inventories_sap, (item) => _.startsWith(item.Warehouse, state.selectWhs.WhsCode));
        state.entries_sap    = (state.selectWhs.WhsCode == '*') ? state.entries_sap :    _.filter(state.entries_sap,    (item) => _.startsWith(item.WhsCode,   state.selectWhs.WhsCode));
        state.deliveries_sap = (state.selectWhs.WhsCode == '*') ? state.deliveries_sap : _.filter(state.deliveries_sap , (item) => _.startsWith(item.WhsCode, state.selectWhs.WhsCode));
    },
}
let actions = {
    getSubItems(context) {
        return new Promise((resolve) => { 
            apiSubItems.subItems().then(function (res) {
                context.commit('MUTATION_SET_SUPER_ITEM', res.data.super);
                context.commit('MUTATION_SET_EXTRA_ITEM', res.data.extra);
                context.commit('MUTATION_SET_REMANENTE_ITEM', res.data.remanente);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
             })
        })
    },
    getProductions(context, data) {
        apiProduction.getProductions(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_PRODUCTION_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    getInventories(context, data) {
        apiInventories.getInventoriesSap(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_INVENTORIES_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    getEntries(context, data) {
        apiEntries.getEntries(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_ENTRIES_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    getDeliveries(context, data) {
        apiDeliveries.getDeliveries(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_DELIVERIES_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    Filters(context) {
        context.commit('MUTATION_SET_FILTERS');
    },
    selectWarehouse(context, item) {
        context.commit('MUTATION_SET_WAREHOUSE_SELECTED', item);
        context.dispatch('Filters');
    },
    setFlag(context, value) {
        context.commit('MUTATION_SET_FLAG_DATA_SAP', value);
    },
    async fetchData(context,data){
       
        try {
            context.dispatch('setFlag', true);
            await context.dispatch('getSubItems')
            await context.dispatch('getProductions',{date1: data.date1,date2: data.date2})
            await context.dispatch('getInventories',{date1: data.date1,date2: data.date2})
            await context.dispatch('getEntries',{date1: data.date1,date2: data.date2})
            await context.dispatch('warehouses_sap/getWarehouses', {}, {root :true});
            await context.dispatch('getDeliveries',{date1: data.date1,date2: data.date2})
            context.dispatch('setFlag', false);
        } catch (error) {
            context.dispatch('setFlag', false);
            console.log(error)   
        }
    }

}

let getters = {
    //Informacion de almacenes
    getWarehouses: (state) => {
         return state.WhsArray
    },

    /**
     *Data de Super productions
     * 
     */
    getArraySuper: (state) => {
        return _.groupBy(_.filter(state.production_sap, (obj) => state.superItem.includes(obj.ItemCode)), 'ItemCode')
    },
    getTotalSuper: (state, getter) => {
        return _.sumBy(_.flatMap(getter.getArraySuper), 'Quantity');
    },
    getTotalSuperMessage: (state, getter) => {
        let text = _.map(getter.getArraySuper, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].ProdName;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
     *Data de Super inventories
     * 
     */
    getArrayInvSuper: (state) => {
        return _.groupBy(_.filter(state.inventories_sap, (item) => state.superItem.includes(item.ItemCode)), 'ItemCode');
    },
    getInvSuperTotal: (state, getter, rootState) => {
        return _.sumBy(_.flatMap(getter.getArrayInvSuper, items => _.filter(items, { 'DocDate': rootState.dates.dateInitial })), 'Stock');
    },
    getTotalSuperInvMessage: (state, getter, rootState) => {
        let text = _.map(getter.getArrayInvSuper, (items) => {
            let totalQuantity = _.sumBy(_.filter(items, { 'DocDate': rootState.dates.dateInitial }), 'Stock');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
     *Data de Super deliveries
     * 
     */
    getArrayDeliveriesSuper: (state) => {
        return _.mapValues(_.groupBy(_.filter(state.deliveries_sap, (item) => {return _.some(item.Lines, (line) => _.includes(state.superItem, line.ItemCode));}), (entry) => entry.Lines[0].ItemCode), (items) => items.map((item) => item.Lines[0]));
    },
    getDeliveriesSuperTotal:(state, getter)=>{
        let superProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.superItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        let sumQuantity = _.chain(superProdSap).flatMap((entries, key) => entries.map(entry => ({ key, ...entry }))).filter(entry => entry.Lines.some(line => line.ItemCode === "PCFO0001")).sumBy(entry => entry.Lines.find(line => line.ItemCode === "PCFO0001").Quantity).value();
        return  _.sumBy(_.flatMap(getter.getArrayDeliveriesSuper, _.values), 'Quantity') + sumQuantity;
    },
    getDeliveriesSuperMessage:(state, getter)=>{
        let text = _.map(getter.getArrayDeliveriesSuper, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    getProductionSuperItems:(state)=>{
        let extraProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.superItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        return   _.map(extraProdSap, (entries, key) => {
            let filteredEntries = _.filter(entries, entry =>
              _.some(entry.Lines, line => _.includes(state.superItemComplete, line.ItemCode))
            );
          
            return {
              [key]: _.map(filteredEntries, entry => ({
                ProdName: entry.ProdName,
                Quantity: _.sumBy(entry.Lines, line => (_.includes(state.superItemComplete, line.ItemCode) ? line.Quantity : 0)),
              })),
            };
          });
     
    },
    getProductionSuperItemsMessage:(state,getter)=>{
        let message = _.flatMap(getter.getProductionSuperItems, (items) => {
            let detail = _.map(items, (item) => `${item[0].ProdName} ${_.floor( _.sumBy(item, 'Quantity'), 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} lts`);
            return `Consumo de Super en ${detail.join(', ')}`;
          }).join(' , ');
    
        return getter.getDeliveriesSuperMessage + ' ,' +message
    },
    /**
    *Data de Extra productions
    * 
    */
    getArrayExtra: (state) => {
        return _.groupBy(_.filter(state.production_sap, (obj) => state.extraItem.includes(obj.ItemCode)), 'ItemCode')
    },
    getTotalExtra: (state, getter) => {
        return _.sumBy(_.flatMap(getter.getArrayExtra), 'Quantity');
    },
    getTotalExtraMessage: (state, getter) => {
        let text = _.map(getter.getArrayExtra, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].ProdName;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de Extra inventories
    * 
    */
    getArrayInvExtra: (state) => {
        return _.groupBy(_.filter(state.inventories_sap, (item) => state.extraItem.includes(item.ItemCode)), 'ItemCode');
    },
    getInvExtraTotal: (state, getter, rootState) => {
        return _.sumBy(_.flatMap(getter.getArrayInvExtra, items => _.filter(items, { 'DocDate': rootState.dates.dateInitial })), 'Stock');

    },
    getTotalExtraInvMessage: (state, getter, rootState) => {
        let text = _.map(getter.getArrayInvExtra, (items) => {
            let totalQuantity = _.sumBy(_.filter(items, { 'DocDate': rootState.dates.dateInitial }), 'Stock');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de Extra deliveries
    * 
    */
    getArrayDeliveriesExtra: (state) => {
         return _.mapValues(_.groupBy(_.filter(state.deliveries_sap, (item) => {return _.some(item.Lines, (line) => _.includes(state.extraItem, line.ItemCode));}), (entry) => entry.Lines[0].ItemCode), (items) => items.map((item) => item.Lines[0]));
    },
    getDeliveriesExtraTotal:(state, getter)=>{
        let extraProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.extraItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        let sumQuantity = _.chain(extraProdSap).flatMap((entries, key) => entries.map(entry => ({ key, ...entry }))).filter(entry => entry.Lines.some(line => line.ItemCode === "PCFO0002")).sumBy(entry => entry.Lines.find(line => line.ItemCode === "PCFO0002").Quantity).value();
        return  _.sumBy(_.flatMap(getter.getArrayDeliveriesExtra, _.values), 'Quantity')+ sumQuantity;
    },
    getDeliveriesExtraMessage:(state, getter)=>{
        let text = _.map(getter.getArrayDeliveriesExtra, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    getProductionExtraItems:(state)=>{
        let extraProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.extraItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        return   _.map(extraProdSap, (entries, key) => {
            let filteredEntries = _.filter(entries, entry =>
              _.some(entry.Lines, line => _.includes(state.extraItemComplete, line.ItemCode))
            );
          
            return {
              [key]: _.map(filteredEntries, entry => ({
                ProdName: entry.ProdName,
                Quantity: _.sumBy(entry.Lines, line => (_.includes(state.extraItemComplete, line.ItemCode) ? line.Quantity : 0)),
              })),
            };
          });
     
    },
    getProductionExtraItemsMessage:(state,getter)=>{
        let message = _.flatMap(getter.getProductionExtraItems, (items) => {
            let detail = _.map(items, (item) => `${item[0].ProdName} ${_.floor( _.sumBy(item, 'Quantity'), 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} lts`);
            return ` Consumo de Extra en ${detail.join(', ')}`;
          }).join(' , ');
    
        return getter.getDeliveriesExtraMessage + ' ,' +message
    },
    /**
    *Data de Remanente productions
    * 
    */
    getArrayRemanente: (state) => {
        return _.groupBy(_.filter(state.production_sap, (obj) => state.remanenteItem.includes(obj.ItemCode)), 'ItemCode')
    },
    getTotalRemanente: (state, getter) => {
        return _.sumBy(_.flatMap(getter.getArrayRemanente), 'Quantity');
    },
    getTotalRemanenteMessage: (state, getter) => {
        let text = _.map(getter.getArrayRemanente, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].ProdName;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de remanente inventories
    * 
    */
    getArrayInvRemanente: (state) => {
        return _.groupBy(_.filter(state.inventories_sap, (item) => state.remanenteItem.includes(item.ItemCode)), 'ItemCode');
    },
    getInvRemanenteTotal: (state, getter, rootState) => {
        return _.sumBy(_.flatMap(getter.getArrayInvRemanente, items => _.filter(items, { 'DocDate': rootState.dates.dateInitial })), 'Stock');

    },
    getTotalRemanenteInvMessage: (state, getter, rootState) => {
        let text = _.map(getter.getArrayInvRemanente, (items) => {
            let totalQuantity = _.sumBy(_.filter(items, { 'DocDate': rootState.dates.dateInitial }), 'Stock');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de Remanente deliveries
    * 
    */
    getArrayDeliveriesRemanente: (state) => {
        return _.mapValues(_.groupBy(_.filter(state.deliveries_sap, (item) => {return _.some(item.Lines, (line) => _.includes(state.remanenteItem, line.ItemCode));}), (entry) => entry.Lines[0].ItemCode), (items) => items.map((item) => item.Lines[0]));
    },
    getDeliveriesRemanenteTotal:(state, getter)=>{
        let remanenteProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.rementeItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        let sumQuantity = _.chain(remanenteProdSap).flatMap((entries, key) => entries.map(entry => ({ key, ...entry }))).filter(entry => entry.Lines.some(line => line.ItemCode === "PCFO0003")).sumBy(entry => entry.Lines.find(line => line.ItemCode === "PCFO0003").Quantity).value();
        return  _.sumBy(_.flatMap(getter.getArrayDeliveriesRemanente, _.values), 'Quantity') + sumQuantity;
    },
    getDeliveriesRemanteMessage:(state, getter)=>{
        let text = _.map(getter.getArrayDeliveriesRemanente, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    getProductionRemanenteItems:(state)=>{
        let remanenteProdSap  = _(state.production_sap).filter((item) => item.Lines.some((line) => state.rementeItemComplete.includes(line.ItemCode))).groupBy('ItemCode').value();
        return   _.map(remanenteProdSap, (entries, key) => {
            let filteredEntries = _.filter(entries, entry =>
              _.some(entry.Lines, line => _.includes(state.rementeItemComplete, line.ItemCode))
            );
          
            return {
              [key]: _.map(filteredEntries, entry => ({
                ProdName: entry.ProdName,
                Quantity: _.sumBy(entry.Lines, line => (_.includes(state.rementeItemComplete, line.ItemCode) ? line.Quantity : 0)),
              })),
            };
          });
     
    },
    getProductionRemanenteItemsMessage:(state,getter)=>{
        let message = _.flatMap(getter.getProductionRemanenteItems, (items) => {
            let detail = _.map(items, (item) => `${item[0].ProdName} ${_.floor( _.sumBy(item, 'Quantity'), 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} lts`);
            return `Consumo de remanente en ${detail.join(', ')}`;
          }).join(' , ');
    
        return (getter.getDeliveriesRemanteMessage=="")?message : getter.getDeliveriesRemanteMessage + ' ,' +message
    },
    /**
    *Data de Combustible Alterno Entries
    * 
    */
    getEntryCAlternoMpTotal: (state) => {
        return _.sumBy(state.entries_sap, (entry) => entry.Lines[0].Quantity)
    },
    /**
    *Data de Combustible Alterno inventories
    * 
    */
    getArrayInvCAlternoMp: (state) => {
        return _.groupBy(_.filter(state.inventories_sap, (item) => state.cAlternoMpItem.includes(item.ItemCode)), 'ItemCode');
    },
    getInvCAlternoMpTotal: (state, getter, rootState) => {
        return _.sumBy(_.flatMap(getter.getArrayInvCAlternoMp, items => _.filter(items, { 'DocDate': rootState.dates.dateInitial })), 'Stock');

    },
    getTotalCAlternoMpInvMessage: (state, getter, rootState) => {
        let text = _.map(getter.getArrayInvCAlternoMp, (items) => {
            let totalQuantity = _.sumBy(_.filter(items, { 'DocDate': rootState.dates.dateInitial }), 'Stock');
            let name = items[0].Dscription;
            return `${name} : ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de Combustible Alterno consumo
    * 
    */
    getConsumoAlternoMpProd:(state)=>{
        return  _.groupBy(_.filter(state.production_sap, item => {
            let mpcLines = item.Lines.filter(line => line.ItemCode === "MPCO0001");
            return mpcLines.length == 1 &&  item.ItemCode == 'PCFO0005'
        }),'ItemCode');
    },
    getConsumoAlternoMpProdTotal:(state,getter)=>{
        let total = 0;
        _.forEach(getter.getConsumoAlternoMpProd, function(article) {
            _.forEach(article, function(prod) {
                _.forEach(prod.Lines, function(value) {
                    if(value.ItemCode == 'MPCO0001' )
                        total += value.Quantity
                });
          });
        });
        return total
    },
    getConsumoAlternoMpProdMessage:(state,getter)=>{
        let text = _.map(getter.getConsumoAlternoMpProd, (items) => {
            let name = items[0].ProdName;
            let totalQuantity=0;
            _.map(items, (prod) => {
                 totalQuantity += _.sumBy(_.filter(prod.Lines, { 'ItemCode': 'MPCO0001' }), 'Quantity');
            });
            return `Consumo de Alterno en ${name} : ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    getConsumoAlternoMpReactor:(state)=>{
        return  _.sumBy(state.production_sap, (entry) => {
            let mpcLines = entry.Lines.filter(line => line.ItemCode === "MPCO0001");
            if (mpcLines.length > 1 ) {
              let maxQuantity = _.maxBy(mpcLines, 'Quantity').Quantity;
              return maxQuantity;
            }
            if (!_.includes(['PCFO0005'], entry.ItemCode)  && mpcLines.length == 1) {
                let maxQuantity = _.sumBy(mpcLines, 'Quantity');
                return maxQuantity;
              }
          
            return 0;
        });
    
    },
    getConsumoAlternoMpQuemador:(state)=>{
        return  _.sumBy(state.production_sap, (entry) => {
            let mpcLines = entry.Lines.filter(line => line.ItemCode === "MPCO0001");
            if (mpcLines.length > 1) {
              let minQuantity = _.minBy(mpcLines, 'Quantity').Quantity;
              return minQuantity;
            }
          
            return 0;
        });
    
    },
    /**
    *Data de C-Alterno productions
    * 
    */
    getArrayCalterno: (state) => {
        return _.groupBy(_.filter(state.production_sap, (obj) => state.cAlternoItem.includes(obj.ItemCode)), 'ItemCode')
    },
    getTotalCalterno: (state, getter) => {
        return _.sumBy(_.flatMap(getter.getArrayCalterno), 'Quantity');
    },
    getTotalCalternoMessage: (state, getter) => {
        let text = _.map(getter.getArrayCalterno, (items) => {
            let totalQuantity = _.sumBy(items, 'Quantity');
            let name = items[0].ProdName;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de C-alterno inventories
    * 
    */
    getArrayInvCAlterno: (state) => {
        return _.groupBy(_.filter(state.inventories_sap, (item) => state.cAlternoItem.includes(item.ItemCode)), 'ItemCode');
    },
    getInvCAlternoTotal: (state, getter, rootState) => {
        return _.sumBy(_.flatMap(getter.getArrayInvCAlterno, items => _.filter(items, { 'DocDate': rootState.dates.dateInitial })), 'Stock');

    },
    getTotalCAlternoInvMessage: (state, getter, rootState) => {
        let text = _.map(getter.getArrayInvCAlterno, (items) => {
            let totalQuantity = _.sumBy(_.filter(items, { 'DocDate': rootState.dates.dateInitial }), 'Stock');
            let name = items[0].Dscription;
            return `${name}: ${_.floor(totalQuantity, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} Lts`;
        });
        return text.join(', ')
    },
    /**
    *Data de C-alterno Salidas
    * 
    */
   getDeliveriesCAlternoTotal:(state)=>{
        return _.sumBy(_.flatMap(state.deliveries_sap, 'Lines'), (line) => state.cAlternoItem.includes(line.ItemCode) ? line.Quantity : 0);
   }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}