const namespaced = true

import api from '../../../api/sap/usage_fiscal_sap.js';

const state = {
    usages_fiscal_sap: [],
}
const mutations = {
    MUTATION_SET_USAGES_FISCAL(state, data){
         state.usages_fiscal_sap = data;
    },
}
const actions = {
    getUsageFiscal(context) {
        api.getUsageFiscal().then(function (res) {
            context.commit('MUTATION_SET_USAGES_FISCAL', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}