const namespaced = true

import api from '../../../api/sap/purchases_order_sap.js';
import { downloadFile } from '../../../src/helpers/util.js'
import _ from 'lodash';

const state = {
    data: null,
    loading: false,
    loadingDownload: false,
    loadingDocument: false,
    // supplierSelect: { CardCode: '*', CardName: 'Todos' },
    supplierSelect: null,
    whsSelect: { whs_code: '*', whs_name: 'Todos' },
    // itemSelect: { ItemCode: '*', ItemName: 'Todos' },
    itemSelect: null,
    purchaseOrder: null,

}
const mutations = {
    MUTATION_SET_DATA(state, data) {
        state.data = data;
    },
    MUTATION_SET_LOADING(state, value) {
        state.loading = value;
    },
    MUTATION_SET_LOADING_DOWNLOAD(state, value) {
        state.loadingDownload = value;
    },
    MUTATION_SET_LOADING_DOCUMENT(state, value) {
        state.loadingDocument = value;
    },
    MUTATION_SET_SUPPLIER_SELECT(state, value) {
        state.supplierSelect = (value != null) ? value : null;
    },
    MUTATION_SET_WHS_SELECT(state, value) {
        state.whsSelect = (value != null) ? value : { whs_code: '*', whs_name: 'Todos' };
    },
    MUTATION_SET_ITEM_SELECT(state, value) {
        state.itemSelect = (value != null) ? value : null;
    },
    MUTATION_SET_PURCHASE_ORDER(state, value) {
        state.purchaseOrder = value
    },
}
const actions = {
    getByWhs(context, data) {
        context.commit('MUTATION_SET_LOADING', true);
        return new Promise((resolve) => {
            api.byWhs(data.page, data.perPage, data.date1, data.date2, data.item, data.cardCode).then(function (res) {
                context.commit('MUTATION_SET_DATA', res.data);
                context.commit('MUTATION_SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
    clearData(context, data) {
        context.commit('MUTATION_SET_DATA', data);
        context.commit('MUTATION_SET_SUPPLIER_SELECT', 'Todos');
        context.commit('MUTATION_SET_WHS_SELECT', { whs_code: '*', whs_name: 'Todos' });
    },
    setSupplier(context, data) {
        context.commit('MUTATION_SET_SUPPLIER_SELECT', data);
    },
    setWhs(context, data) {
        context.commit('MUTATION_SET_WHS_SELECT', data);
    },
    setItem(context, data) {
        context.commit('MUTATION_SET_ITEM_SELECT', data);
    },
    exportDocument(context, data) {
        return new Promise((resolve) => {
            context.commit('MUTATION_SET_LOADING_DOWNLOAD', true);
            api.exportDocument(data).then(function (res) {
                downloadFile(res.data, 'historico de precios.xlsx');
                context.commit('MUTATION_SET_LOADING_DOWNLOAD', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    getPurchaseOrder(context, data) {
        context.commit('MUTATION_SET_LOADING_DOCUMENT', true);
        return new Promise((resolve) => {
            api.getPurchaseOrder(data).then(function (res) {
                context.commit('MUTATION_SET_PURCHASE_ORDER', res.data);
                context.commit('MUTATION_SET_LOADING_DOCUMENT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_DOCUMENT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
}

const getters = {
    getValidate(state) {
        return (state.data != null) ? state.data.items.length : 0
    },

    getPurchasesOrder(state) {
        if (state.data != null) {
            let temp = (state.supplierSelect == null) ? state.data.items : _.filter(state.data.items, ['CardName', state.supplierSelect.CardName]);
            if (state.whsSelect.whs_code != '*')
                temp = _.filter(temp, ['WhsCode', state.whsSelect.whs_code]);
            if (state.itemSelect != null)
                temp = _.filter(temp, ['ItemCode', state.itemSelect.ItemCode]);

            return temp
        } else {
            return [];
        }
    },
    getAntPage(state) {
        return (state.data != null) ? state.data.antPage : false
    },
    getNextPage(state) {
        return (state.data != null) ? state.data.nextPage : false
    },
    getDataTableExport(state, getter) {
        let transformData = _.map(getter.getPurchasesOrder, item => [
            item.DocNum,
            item.DocDate,
            item.ItemCode,
            item.Dscription,
            item.CardName,
            item.WhsCode,
            item.Price,
            item.penultPreComp,
            item.penultFecComp,
        ]);
        return transformData;
    },
    getDocNum(state) {
        return (state.purchaseOrder != null) ? state.purchaseOrder[0].DocNum : '';
    },
    getDate(state) {
        return (state.purchaseOrder != null) ? state.purchaseOrder[0].DocDate : '';
    },
    getComment(state) {
        return (state.purchaseOrder != null) ? state.purchaseOrder[0].Comments : '';
    },
    getDocTotal(state) {
        return (state.purchaseOrder != null) ? state.purchaseOrder[0].DocTotal : '';
    },
    getVatSum(state) {
        return (state.purchaseOrder != null) ? state.purchaseOrder[0].VatSum : '';
    },
    getSubTotal(state) {
        return (state.purchaseOrder != null) ? parseFloat(state.purchaseOrder[0].DocTotal) - parseFloat(state.purchaseOrder[0].VatSum) : '';
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}