
let prefix = '/api/sap/inventories';

function getInventoriesSap(date1, date2) {
    return window.axios.get(prefix+'/general?date1='+date1+'&date2='+date2)
}


export default {
    getInventoriesSap,
}