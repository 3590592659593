import moment from 'moment';

export function downloadFile (data,name){

    var fileURL = window.URL.createObjectURL(new Blob([data], {type:'application/*'}));
    var fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);

    fileLink.click();
}

export function getDateFromNumberFormat(number){
    let start = moment("1899-12-30");
    return (start.add(number, 'days').toDate().toISOString()).substr(0, 10);
}