const namespaced = true

import api from '../../../api/sap/taxes_sap.js';

const state = {
    taxes_sap: [],
}
const mutations = {
    MUTATION_SET_TAXES(state, data){
         state.taxes_sap = data;
    },
}
const actions = {
    getTaxes(context, {validForAR}) {
        api.getTaxes(validForAR).then(function (res) {
            context.commit('MUTATION_SET_TAXES', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}