let prefix = '/api/sap/deliveries-report';
function getDeliveries(date1, date2) {
    return window.axios.get(prefix+'/general?date1='+date1+'&date2='+date2)
}
function downloadExcel(date1, date2, itemCode) {
    return window.axios.post(prefix+'/general/excel?date1='+date1+'&date2='+date2+'&itemCode='+itemCode, { date1: date1, date2: date2, itemCode: itemCode }, { responseType: 'blob' });
}
export default {
    getDeliveries,
    downloadExcel,
}