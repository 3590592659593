let prefix = '/api/quality-indicators';

function all(){
    return window.axios.get(prefix);
}
function getIndex(date1, date2){
    return window.axios.get(prefix+'?date1='+date1+'&date2='+date2);
}
function index(){
    return window.axios.get(prefix);
}
function store(data){
    return window.axios.post(prefix, data);
}
function update(id, data){
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function closed(id){
    return window.axios.post(prefix+'/'+id+'/closed');
}
function cancel(id){
    return window.axios.post(prefix+'/'+id+'/cancel');
}
function createSaleOrderSAP(id){
    return window.axios.post(prefix+'/'+id+'/create-sale-order-sap');
}

export default {
    all,
    getIndex,
    index,
    store,
    update,
    closed,
    cancel,
    createSaleOrderSAP,
}