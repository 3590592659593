const namespaced = true

import api from '../../../api/sap/deliveries_sap.js';
import _ from 'lodash';
import { downloadFile } from '../../../src/helpers/util.js';

const state = {
    deliveries_sap: [],
    deliveries_sap_original: [],
    deliveriesSapFlag: false,
    warehouse_selected_by_deliveries: { WhsCode: '*', WhsName: 'Todos' },
    typeOuts: [
        { typeId: '*', typeName: 'Totales' },
        { typeId: 'F', typeName: 'Facturadas' },
        { typeId: 'P', typeName: 'Pendientes de facturar' },
    ],
    selectProject: 'Todos',
    selectArticle: { ItemCode: '*', Dscription: 'Todos' },
    selectCustomer: 'Todos',
    selectTypeOuts: { typeId: '*', typeName: 'Totales' },
    deliveriesSapExpand: false,
    detailItem :  { itemCode: null , itemName: null },
    showReportZoho: false,
}
const mutations = {
    MUTATION_SET_DELIVERIES_SAP(state, data) {
        state.deliveries_sap = data;
        state.deliveries_sap_original = data;
    },
    MUTATION_SET_DELIVERIES_SAP_FLAG(state, data) {
        state.deliveriesSapFlag = data;
    },
    MUTATION_SET_WAREHOUSE_SELECTED_DELIVERIES(state, data) {
        state.warehouse_selected_by_deliveries = data;
    },
    MUTATION_SET_SELECT_PROJECT(state, value) {
        state.selectProject = value;
    },
    MUTATION_SET_SELECT_ARTICLE(state, value) {
        state.selectArticle = value;
    },
    MUTATION_SET_SELECT_CUSTOMER(state, value) {
        state.selectCustomer = value;
    },
    MUTATION_SET_TYPE_OUTS(state, value) {
        state.selectTypeOuts = value;
    },
    MUTATION_SET_FILTERS(state){
       
        state.deliveries_sap = state.deliveries_sap_original; 
        state.deliveries_sap = (state.selectTypeOuts.typeId == '*')?state.deliveries_sap: (state.selectTypeOuts.typeId == 'F')? _.filter(state.deliveries_sap, (obj) => {return obj.Invoice.DocNum !== null;}): _.filter(state.deliveries_sap, (obj) => {return obj.Invoice.DocNum == null;});
        state.deliveries_sap = (state.warehouse_selected_by_deliveries.WhsCode == '*')?state.deliveries_sap: _.filter(state.deliveries_sap, { 'WhsCode': state.warehouse_selected_by_deliveries.WhsCode});
        state.deliveries_sap = (state.selectProject == 'Todos')?state.deliveries_sap: _.filter(state.deliveries_sap, (delivery) => delivery.Lines.some((line) => line.Project === state.selectProject));
        state.deliveries_sap = (state.selectArticle.ItemCode == '*')?state.deliveries_sap: _.filter(state.deliveries_sap, (delivery) => delivery.Lines.some((line) => line.ItemCode === state.selectArticle.ItemCode));
        state.deliveries_sap = (state.selectCustomer == 'Todos')?state.deliveries_sap:_.filter(state.deliveries_sap, { 'CardName': state.selectCustomer});    
    },
    MUTATION_SET_DELIVERIES_SAP_EXPAND(state, value) {
        state.deliveriesSapExpand = value;
    },
    MUTATION_SET_DETAIL_ITEM(state,value){
        state.detailItem= value
    },
    MUTATION_SET_SHOW_ZOHO_REPORT(state, value) {
        state.showReportZoho = value;
    },
}
const actions = {
    getDeliveries(context, data) {
        context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', true);
        api.getDeliveries(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_DELIVERIES_SAP', res.data);
            context.dispatch('Filters');
            context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', false);
        }).catch((e) => {
            context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', false);
            context.commit('MUTATION_SET_DELIVERIES_SAP', []);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    setDeliveriesSapFlag(context, value) {
        context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', value);
    },
    selectWarehouseByDeliveries(context, item) {
        context.commit('MUTATION_SET_WAREHOUSE_SELECTED_DELIVERIES', item);
        context.dispatch('Filters');
    },
    setSelectProject(context, value) {
        context.commit('MUTATION_SET_SELECT_PROJECT', value);
        context.dispatch('Filters');
    },
    setSelectArticle(context, value) {
        context.commit('MUTATION_SET_SELECT_ARTICLE', value);
        context.dispatch('Filters');
    },
    setSelectCustomer(context, value) {
        context.commit('MUTATION_SET_SELECT_CUSTOMER', value);
        context.dispatch('Filters');
    },
    setSelectTypeOuts(context, value) {
        context.commit('MUTATION_SET_TYPE_OUTS', value);
        context.dispatch('Filters');
    },
    Filters(context) {
        context.commit('MUTATION_SET_FILTERS');
    },
    setDeliveriesSapExpand(context,value){
        context.commit('MUTATION_SET_DELIVERIES_SAP_EXPAND',value)
    },
    setDetailItem(context,value){
        context.commit('MUTATION_SET_DETAIL_ITEM',value)
    },
    setShowZohoReport(context, value) {
        context.commit('MUTATION_SET_SHOW_ZOHO_REPORT', value)
    },
    downloadExcel(context, data) {
        context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', true);
        return new Promise((resolve) => {
            api.downloadExcel(data.date1, data.date2, data.itemCode).then(async function (res) {
                downloadFile(res.data, 'Proeco - Salida de Producto Terminado de '+data.date1+' al '+data.date2+'.xlsx');
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento descargado' }, { root: true });
                context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', false);
                resolve(res.data);
            }).catch((error) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                context.commit('MUTATION_SET_DELIVERIES_SAP_FLAG', false);
            })
        })
    },
}

const getters = {

    getProjects(state) {
        return _.uniq(_.map(state.deliveries_sap_original, 'Lines[0].Project'));
    },
    getArticles(state) {
        return _.uniqBy(_.map(_.filter(state.deliveries_sap_original, (item) => _.startsWith(item.Lines[0].ItemCode, 'PCFO')), item => ({ "ItemCode": item.Lines[0].ItemCode, "Dscription": item.Lines[0].Dscription })), 'ItemCode');
    },
    getCustomers(state) {
        return _.uniq(_.map(state.deliveries_sap_original, 'CardName'));
    },
    getItems:(state,  getters,rootState)=>{
        let Items_sap = (state.warehouse_selected_by_deliveries.WhsCode == '*') ? rootState.ItemsWarehouse_sap.ItemsWarehouse : _.filter(rootState.ItemsWarehouse_sap.ItemsWarehouse, { 'WhsCode': state.warehouse_selected_by_deliveries.WhsCode });
        let ItemsDeliveries = _.uniq(_.map(state.deliveries_sap, 'Lines[0].ItemCode')); 
        let outs = [];
        _.forEach(ItemsDeliveries, Item_deliveries => {
            let sumByQuantity = 0;
            let docTotalPayment = 0;
            let docTotalInvoice = 0;
            let itemName = "";
            let sumByNeto = 0;
            let sumByDensidad = 0;
            let item = _.find(Items_sap, { 'ItemCode': Item_deliveries });
            _.forEach(state.deliveries_sap, delivery => {
                _.forEach(delivery.Lines, line => {
                    if (line.ItemCode == Item_deliveries) {
                        sumByQuantity += line.Quantity;
                        itemName= line.Dscription;
                        sumByNeto+= (line.U_BXP_PESONETO!=null)?parseFloat(line.U_BXP_PESONETO):0;
                        sumByDensidad+= (line.U_HF_DENSIDAD!=null)?parseFloat(line.U_HF_DENSIDAD):0;
                        docTotalPayment+= delivery.Payment.DocTotal;
                        docTotalInvoice+= delivery.Invoice.DocTotal;
                    }
                });
               
            });
            item.sumByQuantity = sumByQuantity;
            item.docTotalInvoice = docTotalInvoice;
            item.docTotalPayment = docTotalPayment;
            item.sumByNeto = sumByNeto; 
            item.AvgPriceAll = _.meanBy(_.filter(rootState.ItemsWarehouse_sap.ItemsWarehouse, function (o) { return  o.ItemCode == item.ItemCode && o.AvgPrice != 0 }), 'AvgPrice');
            item.AvgPriceSaleAll = _.meanBy(_.filter(rootState.ItemsWarehouse_sap.ItemsWarehouse, function (o) { return o.ItemCode == item.ItemCode && o.AvgPriceSale !=0   }), 'AvgPriceSale');
            
            item.sumByDensidad = sumByDensidad / _.filter(state.deliveries_sap, (delivery) => delivery.Lines.some((line) => line.ItemCode === Item_deliveries)).length;
            item.itemName = itemName;

            outs.push(item);
        });
        return outs;
        
    },
    getItemsDetail:(state)=>{
        return _.filter(state.deliveries_sap, (delivery) => delivery.Lines.some((line) => line.ItemCode === state.detailItem.itemCode));  
    },
    getItemsDetailSize:(state , getters)=>{
        return getters.getItemsDetail.length
    },
   
  
   
        
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}