const namespaced = true

import api from '../../../api/sap/entries_sap.js';
import _ from 'lodash';

const state = {
    entriesSapFlag: false,
    entries_sap: [],
    entries_sap_original: [],
    entriesSapExpand: false,
    warehouse_selected_by_entries: { WhsCode: '*', WhsName: 'Todos' },
    showReportZoho: false,
    selectCustomer: 'Todos',
    loading: false,
}
const mutations = {
    MUTATION_SET_ENTRIES_SAP_FLAG(state, value) {
        state.entriesSapFlag = value;
    },
    MUTATION_SET_ENTRIES_SAP_EXPAND(state, value) {
        state.entriesSapExpand = value;
    },
    MUTATION_SET_WAREHOUSE_SELECTED_ENTRIES(state, data) {
        state.warehouse_selected_by_entries = data;
    },
    MUTATION_SET_ENTRIES_CLEAR(state, value) {
        state.entries_sap = value;
        state.entries_sap_items = value;
    },
    MUTATION_SET_ENTRIES_SAP(state, data) {
        state.entries_sap = data;
        state.entries_sap_original = data;
    },
    MUTATION_SET_SHOW_ZOHO_REPORT(state, value) {
        state.showReportZoho = value;
    },
    MUTATION_FILTER(state) {
        state.entries_sap = state.entries_sap_original;
        state.entries_sap = (state.warehouse_selected_by_entries.WhsCode == '*') ? state.entries_sap_original : _.filter(state.entries_sap_original, { 'WhsCode': state.warehouse_selected_by_entries.WhsCode });
        state.entries_sap = (state.selectCustomer == 'Todos')?state.entries_sap:_.filter(state.entries_sap, { 'CardName': state.selectCustomer}); 
    },
    MUTATION_SET_SELECT_CUSTOMER(state, value) {
        state.selectCustomer = value;    
    },
    SET_LOADING(state, val) {
        state.loading = val;
    },
    CLEAR(state) {
        state.entries_sap = [];
        state.loading = false;
    },
}

const actions = {
    getEntries(context, data) {
        context.commit('MUTATION_SET_ENTRIES_SAP_FLAG', true);
        api.getEntries(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_ENTRIES_SAP', res.data);
            context.dispatch('Filters');
            context.commit('MUTATION_SET_ENTRIES_SAP_FLAG', false);
        }).catch((e) => {
            context.commit('MUTATION_SET_ENTRIES_SAP_FLAG', false);
            context.commit('MUTATION_SET_ENTRIES_CLEAR', []);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    setEntriesSapExpand(context, value) {
        context.commit('MUTATION_SET_ENTRIES_SAP_EXPAND', value)
    },
    selectWarehouseByEntries(context, item) {
        context.commit('MUTATION_SET_WAREHOUSE_SELECTED_ENTRIES', item);
        context.dispatch('Filters');
    },
    setEntriesSapFlag(context, value) {
        context.commit('MUTATION_SET_ENTRIES_SAP_FLAG', value);
    },
    setShowZohoReport(context, value) {
        context.commit('MUTATION_SET_SHOW_ZOHO_REPORT', value)
    },
    setSelectCustomer(context, value) {
        context.commit('MUTATION_SET_SELECT_CUSTOMER', value);
        context.dispatch('Filters');
    },
    Filters(context) {
        context.commit('MUTATION_FILTER');
    },
    async getEntriesBySequence(context, sequence) {
        context.commit('SET_LOADING', true);
        try {
            const res = await api.getEntriesBySequence(sequence);
            context.commit('MUTATION_SET_ENTRIES_SAP', res.data);
            context.commit('SET_LOADING', false);
            return res.data;
        } catch (e) {
            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_ENTRIES_SAP', []);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        }
    },
    clear(context){
        context.commit('CLEAR');
    },
}

const getters = {
    getTotalEntries(state) {
        return state.entries_sap.length;
    },

    getItems: (state, getters, rootState) => {
        let Items_sap = rootState.ItemsWarehouse_sap.ItemsWarehouse
        let Items_entry = _.uniq(_.map(state.entries_sap, 'Lines[0].ItemCode'));
        let entries_x_whs = (state.warehouse_selected_by_entries.WhsCode == '*') ? state.entries_sap : _.filter(state.entries_sap, { 'WhsCode': state.warehouse_selected_by_entries.WhsCode });
        let entries = [];
        let itemName = null;
        _.forEach(Items_entry, Item_entry => {
            let sumByQuantity = 0;
            let U_BXP_PESONETO = 0;
            let docTotalPayment = 0;
            let docTotalInvoice = 0;
            let U_HF_DENSIDAD = 0;
            let item = _.find(Items_sap, { 'ItemCode': Item_entry });
            _.forEach(entries_x_whs, entry => {
                _.forEach(entry.Lines, line => {
                    if (line.ItemCode == Item_entry) {
                        sumByQuantity += line.Quantity
                        U_BXP_PESONETO += parseFloat(line.U_BXP_PESONETO)
                        U_HF_DENSIDAD += parseFloat(line.U_HF_DENSIDAD)
                        itemName = line.Dscription;
                    }
                });
                docTotalPayment += entry.Payment.DocTotal;
                docTotalInvoice += entry.Invoice.DocTotal;
            }
            );
            item.sumByQuantity = sumByQuantity
            item.U_BXP_PESONETO = U_BXP_PESONETO
            item.docTotalInvoice = docTotalInvoice
            item.docTotalPayment = docTotalPayment
            item.U_HF_DENSIDAD = U_HF_DENSIDAD / entries_x_whs.length
            item.ItemName = itemName
            entries.push(item);
        });
      
        return  entries;

    },
    getCustomers(state) {
        return _.uniq(_.map(state.entries_sap_original, 'CardName'));
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}