const namespaced = true

import api from '../../api/purchasesRules.js';

const state = {
    users_rules: [],
    distributionXSlpCode:[],
}
const mutations = {
   
    MUTATION_SET_USER_RULES(state, value) {
        state.users_rules = value;
    },
    SET_REQUISITION_DISTRIBUTION_X_SLP_CODE(state, data) {
        state.distributionXSlpCode = data;
    },
    SET_REQUISITION_DISTRIBUTION_X_SLP_CODE_RESET(state) {
        state.distributionXSlpCode = [];
    }
}
const actions = {     
    getUserRules(context){
        return new Promise((resolve, reject) => {
            api.getUserRules().then(resp => {
                context.commit('MUTATION_SET_USER_RULES', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getRequisitionXSlpCode(context, all = false) {
        return new Promise((resolve) => {
            api.getDistributionXSlpCode(all).then(function (res) {
                context.commit('SET_REQUISITION_DISTRIBUTION_X_SLP_CODE', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    addBuyers(context, id) {
        return new Promise((resolve, reject) => {
            api.addBuyers(id).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    restDistributionXSlpCode(context) {
        context.commit('SET_REQUISITION_DISTRIBUTION_X_SLP_CODE_RESET');
    },
    removeBuyers(context, data) {
        return new Promise((resolve, reject) => {
            api.removeBuyers(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}