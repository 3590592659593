let prefix = '/api/next-departures';

function all(){
    return window.axios.get(prefix);
}
function getActive(){
    return window.axios.get(prefix+'/active');
}
function index(){
    return window.axios.get(prefix);
}
function store(data){
    return window.axios.post(prefix, data);
}
function update(id, data){
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function closed(id){
    return window.axios.post(prefix+'/'+id+'/closed');
}
function cancel(id, comment){
    return window.axios.post(prefix+'/'+id+'/cancel', {comment: comment});
}
function createSaleOrderSAP(id){
    return window.axios.post(prefix+'/'+id+'/create-sale-order-sap');
}

function getReports(data){
    return window.axios.get(prefix+'/report?initial_date='+data.initial_date+'&final_date='+data.final_date);
}

export default {
    all,
    getActive,
    index,
    store,
    update,
    closed,
    cancel,
    createSaleOrderSAP,
    getReports
}