const namespaced = true

import api from '../../../api/sap/partners_sap.js';

const state = {
    partners_sap: [],
    addresses_partners_sap: [],
}
const mutations = {
    MUTATION_SET_PARTNERS(state, data){
         state.partners_sap = data;
    },
    MUTATION_SET_ADDRESSES_PARTNERS(state, data){
         state.addresses_partners_sap = data;
    }
}
const actions = {
    getPartners(context, {type}) {
        api.getPartners(type).then(function (res) {
            context.commit('MUTATION_SET_PARTNERS', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getAddresses(context, {code}) {
        api.getAddresses(code).then(function (res) {
            context.commit('MUTATION_SET_ADDRESSES_PARTNERS', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}