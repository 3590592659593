let prefix = '/api/sap/items';
function load_items(){
    return window.axios.get(prefix+'/index');
}
function getArticlesByGroup(group){
    return window.axios.get(prefix+'/by-group/'+group);
}
function load_items_group(ItmsGrpCod, whsCode,page,perPage){
    return window.axios.get(prefix+'/show/groupcode/' + ItmsGrpCod+ '/' + whsCode + '/'+page + '/'+perPage);
}
function items(){
    return window.axios.get(prefix+'/historyPrice');
}
export default {
    load_items,load_items_group,getArticlesByGroup,items
}