const namespaced = true

import api from '../../../api/sap/warehouses_sap.js';
import _ from 'lodash';

const state = {
    warehouses_sap: [],
}
const mutations = {
    MUTATION_SET_WAREHOUSES_SAP(state, data){
         state.warehouses_sap = data;
    }
}
const actions = {
    getWarehouses(context) {
        api.getWarehouses().then(function (res) {
            context.commit('MUTATION_SET_WAREHOUSES_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {
    getWarehousesSales(state){
        const filterEndsWith = ['PT', 'CS', 'RS'];
        return _.filter(state.warehouses_sap, warehouse => {
            const codeEnd = warehouse.WhsCode.slice(-2);
            return _.some(filterEndsWith, end => _.endsWith(codeEnd, end));
        });
    }
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}