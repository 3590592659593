const namespaced = true
import api from '../../../api/sap/inventories_sap.js';
import _ from 'lodash';

const state = {
    items_inventories_sap: [],
    items_inventories_sap_original: [],
    warehouse_selected_by_inventories: { WhsCode: '*', WhsName: 'Todos' },
    inventoriesSapFlag: false,
    inventoriesSapExpand: false,
    showReportZoho: false,
}

const mutations = {
    MUTATION_SET_ITEMS_INVENTORIES_SAP(state, data) {
        state.items_inventories_sap = data
        state.items_inventories_sap_original = data
    },
    MUTATION_SET_WAREHOUSE_SELECTED_INVENTORIES(state, data) {
        state.warehouse_selected_by_inventories = data;
    },
    MUTATION_SET_FILTERS(state){
        state.items_inventories_sap = state.items_inventories_sap_original; 
        state.items_inventories_sap = (state.warehouse_selected_by_inventories.WhsCode == '*')?state.items_inventories_sap: _.filter(state.items_inventories_sap, { 'Warehouse': state.warehouse_selected_by_inventories.WhsCode});
    },
    MUTATION_SET_INVENTORIES_SAP_FLAG(state,value){
        state.inventoriesSapFlag = value; 
    },
    MUTATION_SET_INVENTORIES_SAP_EXPAND(state,value){
        state.inventoriesSapExpand = value; 
    },
    MUTATION_SET_SHOW_ZOHO_REPORT(state, value) {
        state.showReportZoho = value;
    },
}
const actions = {
    getInventories(context, data) {
        return new Promise((resolve) => {
            context.commit('MUTATION_SET_INVENTORIES_SAP_FLAG',true);
            api.getInventoriesSap(data.date1, data.date2).then(function (res) {
                context.commit('MUTATION_SET_ITEMS_INVENTORIES_SAP', res.data);
                context.commit('MUTATION_SET_INVENTORIES_SAP_FLAG',false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_ITEMS_INVENTORIES_SAP', []);
                context.commit('MUTATION_SET_INVENTORIES_SAP_FLAG',false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
    selectWarehouseByInventories(context, item) {
        context.commit('MUTATION_SET_WAREHOUSE_SELECTED_INVENTORIES', item);
        context.dispatch('Filters');
    },
    Filters(context) {
        context.commit('MUTATION_SET_FILTERS');
    },
    setInventoriesSapExpand(context,value) {
        context.commit('MUTATION_SET_INVENTORIES_SAP_EXPAND',value);
    },
    setShowZohoReport(context, value) {
        context.commit('MUTATION_SET_SHOW_ZOHO_REPORT', value)
    },
}

const getters = {
    getWarehouses: (state, getter, rootState) => {
        return _.uniq(state.items_inventories_sap_original.map(item =>
            _.find(rootState.warehouses_sap.warehouses_sap, { 'WhsCode': item.Warehouse })
        ));
    },
    getInventoriesInitial: (state,getter, rootState) => {
        let array = [];
        _.forEach(state.items_inventories_sap , function (value) {
            let tempo = value;
            tempo.whsObject = _.find(rootState.warehouses_sap.warehouses_sap, { 'WhsCode': tempo.Warehouse })
            array.push(tempo)
        });
        return _.groupBy(array, 'ItemCode')
     ;
     
    },
    getInventoriesTable: (state,getter, rootState) => {
        let array = [];
        _.forEach(state.items_inventories_sap, function (value) {
            let tempo = value;
            tempo.whsObject = _.find(rootState.warehouses_sap.warehouses_sap, { 'WhsCode': tempo.Warehouse })
            array.push(tempo)
        });
        return array;
    },

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}