let prefix = '/api/sap/purchase-orders';

function byWhs(page,perPage,date1,date2, item,cardCode) {
    return window.axios.get(prefix+'/byWhs/'+page+'/'+perPage+'?date1='+date1+'&date2='+date2+'&item='+item+'&cardCode='+cardCode)
   
}
function exportDocument(data){
    return window.axios.post(prefix+'/byWhs/export',data,{ responseType: 'blob' });
}
function getPurchaseOrder(docEntry){
    return window.axios.get(prefix+'/show/'+docEntry);
}
export default {
    byWhs,exportDocument,getPurchaseOrder
}