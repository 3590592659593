
let prefix = '/api/calendarFiles';
import _ from 'lodash';

function getDocuments() {
    return window.axios.get(prefix + '/index');
}

function download(data) {
    return window.axios.get(prefix + '/download/' + data, { responseType: 'arraybuffer' });
}

function store(data) {
    let formData = new FormData();
    formData.append('reference', data.reference);
    formData.append('company_id', data.company.id);
    formData.append('name', data.reference);
    formData.append('initial_date', data.initial_date);
    formData.append('final_date', data.final_date);
    formData.append('calendar_group_id', data.group.id);
    _.forEach(data.assign, function (user, key) {
        formData.append('assign[' + key + '][user_id]', user.id);
        formData.append('assign[' + key + '][status]', 1);
    })
    formData.append('days_to_notify', data.days_to_notify);
    formData.append('name_file', data.name_file);
    formData.append('renewal_cost', data.renewal_cost);
    formData.append('plant_id', data.location.id)
    formData.append('status', 'Activo');
    formData.append('currency_type', data.currency_type);

    /*structure directory google drive*/
    formData.append('data[0][name]', 'Nutrimentos');
    formData.append('data[0][idParent]', '');
    formData.append('data[0][file][name]', data.company.name);
    formData.append('data[0][file][id]', data.company.id);
    formData.append('data[0][file][file][name]', 'Calendario de licencias');
    formData.append('data[0][file][file][id]', 1);
    formData.append('data[0][file][file][file]', []);


    return window.axios.post(prefix + '/store', formData, { "Content-Type": "multipart/form-data" });
}

function update(id, data) {
    let key = 0;
    let formData = new FormData();
    formData.append('reference', data.reference);
    formData.append('company_id', data.company.id);
    formData.append('name',data.reference);
    formData.append('initial_date', data.initial_date);
    formData.append('final_date', data.final_date);
    formData.append('calendar_group_id', data.group.id);
    _.forEach(data.assignAnt, function (user) {
        formData.append('assign[' + key + '][user_id]', user.id);
        formData.append('assign[' + key + '][status]', 0);
        key++;
    })
    _.forEach(data.assign, function (user) {
        formData.append('assign[' + key + '][user_id]', user.id);
        formData.append('assign[' + key + '][status]', 1);
        key++;
    })
    formData.append('days_to_notify', data.days_to_notify);
    formData.append('name_file', data.name_file);
    formData.append('renewal_cost', data.renewal_cost);
    formData.append('plant_id', data.location.id)
    formData.append('status', 'Activo');
    formData.append('currency_type', data.currency_type);

    formData.append('data[0][name]', 'Grupo Vals');
    formData.append('data[0][idParent]', '');
    formData.append('data[0][file][name]', data.company.name);
    formData.append('data[0][file][id]', data.company.id);
    formData.append('data[0][file][file][name]', 'Calendario de licencias');
    formData.append('data[0][file][file][id]', 1);
    formData.append('data[0][file][file][file]', []);

    
    return window.axios.post(prefix + '/update/' + id, formData, { "Content-Type": "multipart/form-data" });
}

function deleteCalendarDocument(id) {
    return window.axios.patch(prefix + '/delete/' + id);
}



export default {
    getDocuments, download, store, update, deleteCalendarDocument

}