
let prefix = '/api/sap/entries-report';

function getEntries(date1, date2) {
    return window.axios.get(prefix+'/general?date1='+date1+'&date2='+date2)
}

function getEntriesBySequence(sequence) {
    return window.axios.get(prefix+'/show/sequence/'+sequence)
}
export default {
    getEntries,getEntriesBySequence
}