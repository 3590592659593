const namespaced = true

import api     from '../../api/quality-indicators.js';
import _ from 'lodash';

const state = {
    qualityIndicators: [],
}
const mutations = {
     MUTATION_SET_QUALITY_INDICATORS(state, value) {
          state.qualityIndicators = value;
     },
     MUTATION_ADD_NEXT_DEPARTURE(state, value){
          state.qualityIndicators.push(value)
     },
     MUTATION_UPDATE_NEXT_DEPARTURE(state, value){
          let qualityIndicators = state.qualityIndicators;
          state.qualityIndicators = [];
          _.forEach(qualityIndicators, rol => {
              if(rol.id != value.id){
                  state.qualityIndicators.push(rol);
              }else{
                  state.qualityIndicators.push(value);
              }
          });
     },
     MUTATION_DELETE_NEXT_DEPARTURE(state, value){
          let qualityIndicators = state.qualityIndicators;
          state.qualityIndicators = [];
          _.forEach(qualityIndicators, rol => {
               if(rol.id != value){
                    state.qualityIndicators.push(rol);
               }
          });
     },
}
const actions = {
      // Name Property
     getAll(context) {
          return new Promise((resolve) => { 
               api.all().then(function (res) {
                    context.commit('MUTATION_SET_QUALITY_INDICATORS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     getIndex(context, {date1, date2}) {
          return new Promise((resolve, reject) => { 
               api.getIndex(date1, date2).then(function (res) {
                    context.commit('MUTATION_SET_QUALITY_INDICATORS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
                    reject(error);
               })
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_QUALITY_INDICATOR', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_QUALITY_INDICATOR', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     closed(context, id){
          return new Promise((resolve, reject) => {
               api.closed(id).then(resp => {
                    context.commit('MUTATION_UPDATE_QUALITY_INDICATOR', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     cancel(context, id){
          return new Promise((resolve, reject) => {
               api.cancel(id).then(resp => {
                    context.commit('MUTATION_DELETE_QUALITY_INDICATOR', id);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     createSaleOrderSAP(context, id){
          return new Promise((resolve, reject) => {
               api.createSaleOrderSAP(id).then(resp => {
                    context.commit('MUTATION_UPDATE_QUALITY_INDICATOR', resp.data);
                    context.dispatch('notifications/add', { 'color': 'success', 'text': 'Se creo la OV '+resp.data.sale_order_sap, 'timeout': 8000, 'visibility': true }, { root: true });
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add', { 'color': 'error', 'text': error, 'timeout': 5000, 'visibility': true }, { root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
