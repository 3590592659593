const namespaced = true
import api from '../../../api/sap/ItemsWarehouse';
const state = {
    ItemsWarehouse : []
}

const mutations = {
    MUTATION_SET_ITEMS_WAREHOUSE(state, ItemsWarehouse) {
        state.ItemsWarehouse = ItemsWarehouse
    }
}
const actions = {
    getItemsWarehouse(context, data){
        return new Promise((resolve) => {
            api.getItemsWarehouse(data.date1, data.date2).then(resp => {
                context.commit('MUTATION_SET_ITEMS_WAREHOUSE', resp.data);
                resolve(resp.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            });
        });
    },
    getStock(context, data) {
        return new Promise((resolve) => {
            api.stock(data).then(function (res) {
                context.commit('MUTATION_SET_ITEMS_WAREHOUSE', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            })
        })
    },
}
const getters = {
    getItemsWarehouse(state){
        return state.ItemsWarehouse
    }
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}

