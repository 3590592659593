const namespaced = true

import api from '../../api/next-departures.js';
import _ from 'lodash';

const state = {
     nextDepartures: [],
     reports: [],
     countsReports: {
          open: 0,
          porcentOpen: 'No Aplica',
          closed: 0,
          porcentClosed: 'No Aplica',
          cancel: 0,
          porcentCancel: 'No Aplica'
     },


}
const mutations = {
     MUTATION_SET_NEXT_DEPARTURES(state, value) {
          state.nextDepartures = value;
     },
     MUTATION_ADD_NEXT_DEPARTURE(state, value) {
          state.nextDepartures.push(value)
     },
     MUTATION_UPDATE_NEXT_DEPARTURE(state, value) {
          let nextDepartures = state.nextDepartures;
          state.nextDepartures = [];
          _.forEach(nextDepartures, rol => {
               if (rol.id != value.id) {
                    state.nextDepartures.push(rol);
               } else {
                    state.nextDepartures.push(value);
               }
          });
     },
     MUTATION_DELETE_NEXT_DEPARTURE(state, value) {
          let nextDepartures = state.nextDepartures;
          state.nextDepartures = [];
          _.forEach(nextDepartures, rol => {
               if (rol.id != value) {
                    state.nextDepartures.push(rol);
               }
          });
     },
     MUTATION_SET_REPORTS(state, { data, warehouse }) {
          state.countsReports.closed = 0;
          state.countsReports.cancel = 0;
          state.countsReports.open = 0;
          state.countsReports.porcentOpen = 'No Aplica';
          state.countsReports.porcentClosed = 'No Aplica';
          state.countsReports.porcentCancel = 'No Aplica';
          state.reports = data
          let cantidad = state.reports.length;
          if (cantidad > 0) {
               if (warehouse) {
                    state.reports = _.filter(state.reports, function (report) {
                         return _.some(report.lines, { whs_code: warehouse });
                    });
                    let reportWithWareHouse = state.reports;
                    _.forEach(reportWithWareHouse, val => {
                         switch (val.status_id) {
                              case 3:
                                   state.countsReports.closed += 1;
                                   break;
                              case 4:
                                   state.countsReports.cancel += 1;
                                   break;
                              default:
                                   //1 y 2 Abierto y Reprogramado
                                   state.countsReports.open += 1;
                                   break;
                         }
                    });
                    state.countsReports.porcentOpen = (state.countsReports.open > 0) ? (((state.countsReports.open / reportWithWareHouse.length) * 100).toFixed(2) + ' %') : 'No aplica';
                    state.countsReports.porcentClosed = (state.countsReports.closed > 0) ? (((state.countsReports.closed / reportWithWareHouse.length) * 100).toFixed(2) + ' %') : 'No aplica';
                    state.countsReports.porcentCancel = (state.countsReports.cancel > 0) ? (((state.countsReports.cancel / reportWithWareHouse.length) * 100).toFixed(2) + ' %') : 'No aplica';

               } else {
                    _.forEach(data, val => {
                         switch (val.status_id) {
                              case 3:
                                   state.countsReports.closed += 1;
                                   break;
                              case 4:
                                   state.countsReports.cancel += 1;
                                   break;
                              default:
                                   //1 y 2 Abierto y Reprogramado
                                   state.countsReports.open += 1;
                                   break;
                         }
                    });
                    state.countsReports.porcentOpen = (state.countsReports.open > 0) ? (((state.countsReports.open / cantidad) * 100).toFixed(2) + ' %') : 'No aplica';
                    state.countsReports.porcentClosed = (state.countsReports.closed > 0) ? (((state.countsReports.closed / cantidad) * 100).toFixed(2) + ' %') : 'No aplica';
                    state.countsReports.porcentCancel = (state.countsReports.cancel > 0) ? (((state.countsReports.cancel / cantidad) * 100).toFixed(2) + ' %') : 'No aplica';
               }

          }

     },
}
const actions = {
     getAll(context) {
          return new Promise((resolve) => {
               api.all().then(function (res) {
                    context.commit('MUTATION_SET_NEXT_DEPARTURES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     getActive(context) {
          return new Promise((resolve) => {
               api.getActive().then(function (res) {
                    context.commit('MUTATION_SET_NEXT_DEPARTURES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     store(context, data) {
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_NEXT_DEPARTURE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     update(context, { id, data }) {
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_NEXT_DEPARTURE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     closed(context, id) {
          return new Promise((resolve, reject) => {
               api.closed(id).then(resp => {
                    context.commit('MUTATION_UPDATE_NEXT_DEPARTURE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     cancel(context, {id, comment}) {
          return new Promise((resolve, reject) => {
               api.cancel(id, comment).then(resp => {
                    context.commit('MUTATION_DELETE_NEXT_DEPARTURE', id);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     createSaleOrderSAP(context, id) {
          return new Promise((resolve, reject) => {
               api.createSaleOrderSAP(id).then(resp => {
                    context.commit('MUTATION_UPDATE_NEXT_DEPARTURE', resp.data);
                    context.dispatch('notifications/add', { 'color': 'success', 'text': 'Se creo la OV ' + resp.data.sale_order_sap, 'timeout': 8000, 'visibility': true }, { root: true });
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add', { 'color': 'error', 'text': error, 'timeout': 5000, 'visibility': true }, { root: true });
                    reject(error);
               });
          });
     },
     getReports(context, { data, warehouse }) {
          return new Promise((resolve) => {
               api.getReports(data).then(function (res) {
                    context.commit('MUTATION_SET_REPORTS', { data: res.data, warehouse: warehouse });
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Reportes encontrados correctamente' }, { root: true });
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
                    context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
               })
          })
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
