const namespaced = true

import _ from 'lodash';
import api from '../../../api/sap/productions_sap.js';

const state = {
    productions_sap: [],
    productions_sap_filter: [],

    item_selected_by_prod: {},
    warehouse_selected_by_prod: {},
    items_by_prod: [],
}
const mutations = {
    MUTATION_SET_PRODUCTION_SAP(state, data){
        state.productions_sap = data;
    },
    MUTATION_SET_PRODUCTION_SAP_FILTER(state, data){
        state.productions_sap_filter = data;
    },
    MUTATION_SET_ITEMS_BY_PROD(state, data){
         state.items_by_prod = data;
    },

    MUTATION_SET_ITEM_SELECTED_PROD(state, data){
        state.item_selected_by_prod = data;
    },
    MUTATION_SET_WAREHOUSE_SELECTED_PROD(state, data){
        state.warehouse_selected_by_prod = data;
    },
}
const actions = {
    getProductions(context, data) {
        api.getProductions(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_PRODUCTION_SAP', res.data);
            context.dispatch('setFilterProductions');
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    setFilterProductions({commit, state}){
        commit('MUTATION_SET_PRODUCTION_SAP_FILTER', _.filter(state.productions_sap, prod => {
            return (state.warehouse_selected_by_prod.WhsCode)?prod.Warehouse == state.warehouse_selected_by_prod.WhsCode:true;
        }));
    },
    setItemsByProd({commit, rootState}){
        commit('MUTATION_SET_ITEMS_BY_PROD', _.filter(rootState.items_sap.items_avaible, arti => {
            return arti.SellItem == 'Y' && arti.TreeType == 'P';
        }));
    },

    selectItemByProd(context, item){
        context.commit('MUTATION_SET_ITEM_SELECTED_PROD', item);
    },
    async selectWarehouseByProd(context, warehouse){
        await context.commit('MUTATION_SET_WAREHOUSE_SELECTED_PROD', warehouse);
        await context.dispatch('setItemsByProd');
        await context.dispatch('setFilterProductions');
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}