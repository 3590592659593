let prefix = '/api/sap/supplier-invoices';
let prefixReport = '/api/sap/supplier-invoices-report';

function getAuthInvoices(date1,date2,cardCode){
    return window.axios.get(prefix+'/authorizations_invoices?date1='+date1+'&date2='+date2+'&cardCode='+cardCode);
}
function getInvoicesSupplier(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function getGenaralPurchases(date1, date2){
    return window.axios.get(prefixReport+'/general?date1='+date1+'&date2='+date2);
}
function downloadExcel(date1, date2) {
    return window.axios.post(prefixReport+'/general/excel?date1='+date1+'&date2='+date2, { date1: date1, date2: date2 }, { responseType: 'blob' });
}
function getExpenses(date1, date2){
    return window.axios.get(prefixReport+'/expense?date1='+date1+'&date2='+date2);
}
function downloadExcelExpense(project, date1, date2) {
    return window.axios.post(prefixReport+'/expense/excel?project='+project+'&date1='+date1+'&date2='+date2, { date1: date1, date2: date2 }, { responseType: 'blob' });
}
function getDevolucionIva(date1, date2 , docTotalIni , docTotalFin , DocCur , cardName){
    return window.axios.get(prefix+'/DevolucionIva?date1='+date1+'&date2='+date2+'&docTotalIni='+docTotalIni+'&docTotalFin='+docTotalFin+'&DocCur='+DocCur+'&cardName='+cardName);
}
function getDevolucionIvaExport(data){
    return window.axios.post(prefix+'/DevolucionIva/export',data,{ responseType: 'blob' });
}
export default {
    getAuthInvoices,
    getInvoicesSupplier,
    getGenaralPurchases,
    getExpenses,
    downloadExcel,
    downloadExcelExpense,
    getDevolucionIva,
    getDevolucionIvaExport
}